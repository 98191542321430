import http from "./http"
const baseUrl = 'https://api.gangpinhui.cn/api'
const payUrl = 'https://pay.gxb.cn/api'
/**
 * 
 * @ 接口地址 https://console-docs.apipost.cn/preview/982a960da6c56d44/56b5eefa73707d5c?target_id=6e64c1f4-8f18-4ab4-b562-095ee8fb90ff
  * 
  * */
//采购单发布 卖货单发布
export const $apiBuyerCreateOrder = params => http({ method: "post", url: baseUrl + '/buyer/create/order', data: params })

// 采购单 卖货单 列表  order_type:  必填  订单类型 1为买家单 2为卖家单  //  /get/order/list
export const $apiGetOrderList = params => http({ method: "post", url: baseUrl + '/get/order/list', data: params })

// 我要撤单
export const $apiCancelAnOrder = params => http({ method: "post", url: baseUrl + '/cancel/an/order', data: params })


//个人中心订单列表
export const $apiGetUserOrderList = params => http({ method: "post", url: baseUrl + '/get/user/order/list', data: params })

//获取支付公司银行信息
export const $apiSellerCompanyBankCardInfo = params => http({ method: "post", url: baseUrl + '/seller/company/bank/card/info', data: params })

// 订单的状态修改
export const $apiOrderStateUpdate = params => http({ method: "post", url: baseUrl + '/order/state/update', data: params })


// 拒买拒卖操作
export const $apiRefuseBuyOrSellRes = params => http({ method: "post", url: baseUrl + '/refuse/buy/or/sell/res', data: params })

//是否是钢品汇联盟商家
export const $apiIfunionCompany = params => http({ method: 'post', url: baseUrl + '/if/union/company', data: params })

// 钢品汇vip信息
export const $apiVipInfo = params => http({ method: 'post', url: baseUrl + '/vip/info', data: params })

//上传凭证
export const $apiVoucherUpload = data => http({ method: 'post', url: baseUrl + '/voucher/upload', data })

// 凭证信息返回
export const $apiCredentialStatus = data => http({ method: 'post', url: baseUrl + '/credential/status', data })

// 主动取消订单接口
export const $apiInitiativeCancelOrder = data => http({ method: 'post', url: baseUrl + '/initiative/cancel/order', data })

//@详情页的接口
export const $apiGetOrderInfo = data => http({ method: 'post', url: baseUrl + '/get/order/info', data })


//@详情页公司信息接口
export const $detailCompanyInfo = data => http({ method: 'post', url: payUrl + '/merchants', data })

//@支付宝支付
export const $apiAliPay = data => http({ method: 'post', url: payUrl + '/payment/alipay', data })