import http from "./http"
const baseUrl = 'https://api.gangpinhui.cn/api'
/**
 * 
 * @ 接口地址 https://console-docs.apipost.cn/preview/982a960da6c56d44/56b5eefa73707d5c?target_id=6e64c1f4-8f18-4ab4-b562-095ee8fb90ff
  * 
  * */

// 定金支付
export const $apiDepositPay = params => http({method:"post",url:baseUrl+'/deposit/pay', data:params})


//订单详情页
export const $apiOrderInfo = params => http({method:"post",url:baseUrl+'/order/info', data:params})

// 卖家\买家接单
export const $apiOrderReceiving = params => http({method:"post",url:baseUrl+'/order/receiving', data:params})