// 选择商品单位  个==>1  件==>2  盒子==>3
let screen = { tons: ['个', '件', '盒', "套"] }
const userInfo = JSON.parse(localStorage.getItem("user_info"))

//过滤商品单位
let filterScreen = (index) => {
    return screen.tons[index - 1]
}


// 获取指定信息：返回文字和图标
export const getDesigneeInfo = (type, item) => {
    if (!userInfo) {
        //如果没有userInfo的话可能就是没登录。那么就显示成 ***** 
        return item.specify == 1
    }
    if (item.status == 4) {
        return
    }
    // type:1.icon,2.文字说明 &#xe642;  &#xe6dd;
    if (type == 1) {

        /* return (userInfo.id === item.userId &&item.exclusive_user_id || userInfo.id === item.exclusive_user_id || userInfo.company_id === item.exclusive_company&&!item.exclusive_user_id) ? "&#xe7a1; ":"&#xe792; " */

        const iconfont = (userInfo.id == item.release_user?.id && item?.specify_user.user_id || userInfo.id == item?.specify_user.user_id || userInfo.company_id == item.specify_user.company_id && !item.specify_user.user_id) ? "&#xe7a1; " : "&#xe792; "
        return iconfont
    }
    if (type == 2) {
        if (userInfo.id == item.release_user?.id && item.specify_user.user_id) return "您发的指定单"
        if (userInfo.id == item.specify_user.user_id) return (item.release_user?.name + "指定给您的订单")
        if (userInfo.company_id == item.specify_user.company_id) return (item.release_user?.name + "指定给您公司的订单")
        if (item.specify_user.user_id) return (item.release_user?.name + "发给其他人的指定单")
        if (item.specify_user.company_id) return (item.release_user?.name + "发给其他公司的指定单")
    }

    if (type == 3) {
        if (userInfo.id == item.release_user?.id && item.specify_user.user_id) return false
        if (userInfo.id == item.specify_user.user_id) return false
        if ((userInfo.company_id == item.specify_user.company_id && userInfo.id == item.specify_user.user_id) || (userInfo.company_id == item.specify_user.company_id && !item.specify_user.user_id)) return false
        if (item.specify_user.user_id) return true
        if (item.specify_user.company_id) return true
    }
}



//计算的精确问题
function accMul(arg1, arg2) {
    let r1 = arg1.toString();
    let r2 = arg2.toString();
    let m = 0;
    try {
        m += arg1.toString().split('.')[1].length
    } catch (e) { }
    try {
        m += arg2.toString().split('.')[1].length
    } catch (e) { }
    return (Number(r1.replace('.', '')) * Number(r2.replace('.', ''))) / Math.pow(10, m)
}

//@过滤是否可以查看订单
export function filterLookOrder(orderInfo, key, suffix = '', curObj) {
    const orderType = orderInfo.order_type
    const userInfo = JSON.parse(localStorage.getItem('user_info'))
    const keys = key.split('.')
    if (orderType == 3) {
        const _w = orderLimit(orderInfo, userInfo.merchant_type?.includes('4'))
        if (_w) {
            return _w
        }
        //卖货单
        let _s = _mergeValue(curObj ? curObj : orderInfo, keys)
        return _s ? (_s + suffix) : '--'
    } else {
        if (userInfo && userInfo.merchant_type?.includes('4')) {
            const _w = orderLimit(orderInfo, userInfo.merchant_type?.includes('4'))
            if (_w) {
                return _w
            }
            let _s = _mergeValue(curObj ? curObj : orderInfo, keys)
            return _s ? (_s + suffix) : '--'
        }
    }


    function _mergeValue(item, keys) {
        return keys.reduce((prev, k) => {
            return prev[k]
        }, item);
    }

    return "您不是钢制品联盟商家"
}


// 下单限制
export function orderLimit(item, isUnion) {
    if (userInfo.id == item.release_user?.id) return false  // 自己发的单可以看 getDesigneeInfo
    if (!isUnion && item.order_type == 2) {
        return "您不是钢铁制品联盟商家"
    }
    if (item.specify) {
        if (item.specify_user.company_id && item.specify_user.company_id != userInfo.company_id) {
            return "您不是该笔订单的指定公司"
        }
        if (item.specify_user.user_id && item.specify_user.user_id != userInfo.id) return "您不是该笔订单的指定人"
    }
    return false
}


export function checkOrderBuy(orderInfo) {
    const orderType = orderInfo.order_type
    const userInfo = JSON.parse(localStorage.getItem('user_info'))
    if (orderType == 3) {
        //卖货单 谁都可以接 
        return false
    } else {
        if (userInfo && userInfo.merchant_type?.includes('4')) {
            return false
        } else {
            return '您不是联盟商家，无法接单! 如有疑问，请联系 400-699-0208'
        }
    }
}

export const payList = [
  { id: 4, text: '个人余额支付' }, { id: 5, text: '锁价保证金支付' }, { id: 6, text: '支付宝支付' }, { id: 3, text: '微信支付' }
]
export {
    screen,
    filterScreen,
    accMul,
}