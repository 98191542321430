import http from "./http"
const baseUrl = 'https://pay.gxb.cn/api'

// 品种商家
export const $apiCompanyVariety = (data) => http({ method: "get", url: baseUrl+"/company/variety", params:data, }) 
// 商品列表
export const $apiGoodsList = (data) => http({ method: "post", url: baseUrl+"/Order/goods/list", params:data, }) 
// 新商品列表
export const $apiNewGoodsList = (data) => http({ method: "post", url: baseUrl+"/Goods/order/list", params:data, }) 
// 商品详情
export const $apiGoodsDetail = (data) => http({ method: "get", url: baseUrl+"/goods/info", params:data, }) 
// 订单发布
export const $apiOrderRelease = (data) => http({ method: "post", url: baseUrl+"/user/news/sales", params:data, }) 
// 快捷发布
export const $apiOrderReleaseFast = (data) => http({ method: "post", url: baseUrl+"/news/sales/fast", data, }) 
// 订单下单
export const $apiOrderBuy = (data) => http({ method: "post", url: baseUrl+"/Order/Order", data, }) 
// 支付
export const $apiOrderPay = (data) => http({ method: "post", url: baseUrl+"/Order/PayApi", data, }) 
// 线下支付
export const $apiOrderOffline = (data) => http({ method: "post", url: baseUrl+"/Goods/Web/orderOffline", data, })
// 尾款支付
export const $apiOrderBalancePay = (data) => http({ method: "post", url: baseUrl+"/Order/balance/PayApi", data, })
// 修改状态
export const $apiRevampState = (data) => http({ method: "post", url: baseUrl+"/Order/revamp/state", data, })  


// 我的发布
export const $apiUserOrderPublish = (data) => http({ method: "post", url: baseUrl+"/user/goods/publish", data, })  
// 我的订单
export const $apiUserOrderList = (data) => http({ method: "post", url: baseUrl+"/user/orders/list", data, })  
// 我的订单 新   'http://192.168.0.20/api'
export const $apiNewUserOrderList = (data) => http({ method: "post", url: baseUrl+"/order/list/new", data, }) 
// 我的订单详情
export const $apiNewOrderInfo = (data) => http({ method: "post", url: baseUrl+"/order/info/new", data, }) 
// 更多订单
export const $apiNewMoreOrder = (data) => http({ method: "post", url: baseUrl+"/pack/order/open", data, }) 
// 统计汇总
export const $apiOrderCollect = (data) => http({ method: "post", url: baseUrl+"/order/collect/count", data, }) 
// 撤销订单
export const $apiUserRevokeOrder = (data) => http({ method: "post", url: baseUrl+"/user/revocation/order", data, })  